import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3100744155/src/autobackup-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "hooks"
    }}>{`Hooks`}</h1>
    <p>{`AutoBackup supports executing pre and post hooks upon resource snapshot creation (only PRIMARY snapshot).
Hook could be AWS Lambda or HTTP endpoint.`}</p>
    <h2 {...{
      "id": "permissions"
    }}>{`Permissions`}</h2>
    <p>{`To allow Lambdas to be invoked by AutoBackup the prefix of the Lambda function name should start with `}<inlineCode parentName="p">{`nc-autobackup`}</inlineCode>{` (default generated role has permission to invoke Lambda function with this prefix).`}</p>
    <p>{`HTTP hooks should not require any permissions (for example it could be AWS Lambda triggered by API Gateway with Open security).`}</p>
    <h2 {...{
      "id": "pre-hook"
    }}>{`Pre hook`}</h2>
    <p>{`Pre hook is executed by AutoBackup in a synchronous mode. That means the system needs to read hook output before continuing the backup process.`}</p>
    <p>{`AutoBackup expects that the following JSON is returned by hook:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "continue_processing": true
}
`}</code></pre>
    <p>{`If lambda will return `}<inlineCode parentName="p">{`{"continue_processing": false}`}</inlineCode>{`, AutoBackup will not attempt to create a resource snapshot. Otherwise, AutoBackup will continue creating a snapshot.`}</p>
    <p>{`If hook will fail with error AutoBackup will not attempt to create a resource snapshot, just like case when hook returned `}<inlineCode parentName="p">{`{"continue_processing": false}`}</inlineCode>{`.`}</p>
    <p>{`AutoBackup sends following JSON as input to pre hook:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "account_id": "123456789012",
    "region": "eu-west-1",
    "id": "/AWS/arn:aws:ec2:us-east-2:123456789012:volume/vol-abcdef",
    "backup_rpo": "48",
    "replication_region": "eu-west-2",
    "resource_type": "aws-ebs"
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field name`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`account_id`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`account where resource is stored, cloud provider ID`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`region`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`region where resource is stored`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`id`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`id of a resource`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`backup_rpo`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`RPO defined in resource's backup policy`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`replication_region`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`region to which a snapshot will be replicated`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`resource_type`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`resource type`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "post-hook"
    }}>{`Post hook`}</h2>
    <p>{`Post hook is executed by AutoBackup in an asynchronous mode. That means AutoBackup just invokes hook with JSON described below and does not read hook output.`}</p>
    <p>{`If invoking hook returns an error, AutoBackup will try to send a notification to the user. The backup process won't be stopped (taking REPLICA snapshot).`}</p>
    <p>{`AutoBackup sends following JSON as input to your post hook:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "account_id": "123456789012",
    "region": "eu-west-1",
    "id": "/AWS/arn:aws:ec2:eu-west-1:123456789012:volume/vol-abcdef",
    "backup_rpo": "48",
    "replication_region": "eu-west-2",
    "resource_type": "aws-ebs",
    "snapshot": {
        "ID": "snap-000ddddddd0000000",
        "AccountID": "AWS__77a7777aa77777aaaaa7a77a7a7aaa7a__123456789012",
        "Created": "2018-09-22T12:42:31Z",
        "Region": "eu-west-1",
        "ResourceID": "/AWS/arn:aws:ec2:eu-west-1:123456789012:volume/vol-abcdef",
        "SnapshotType": "PRIMARY",
        "RestoreData": "{\\"instance_description\\":{},\\"version\\":1}",
        "Deleted": false
  }
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field name`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`account_id`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`account where resource is stored, cloud provider ID`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`region`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`region where resource is stored`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`id`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`id of a resource`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`backup_rpo`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`RPO defined in resource's backup policy`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`replication_region`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`region to which a snapshot will be replicated`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`resource_type`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`resource type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`snapshot`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`information about snapshot`}</td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field name`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ID`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`id of snapshot`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AccountID`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`id of an account`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Created`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`date`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Region`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`region where snapshot exists`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ResourceID`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`id of a resource`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SnapshotType`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`snapshot type PRIMARY or REPLICA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`RestoreData`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`resource description`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Deleted`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`snapshot removed or not`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      